<template>
    <div class="who-we-are" v-if="data">

        <div class="get-to-know">
            <div class="get-to-know-copy">
                <img src="/img/global/sms-logo--mark.svg" alt="Santa Monica Studios logo" class="get-to-know-copy__logo--mark">
                <div class="get-to-know-copy__subheading">{{ data.getToKnow.subheading }}</div>
                <h2 class="get-to-know-copy__header" v-html="data.getToKnow.header"></h2>
                <div class="get-to-know__right-image-mobile" v-scroll-reveal="{ distance: '0px', beforeReveal: revealMobileTop }">
                    <svg role = "img" aria-label = "A decorative frame border." enable-background="new 0 0 688.74 461.48" viewBox="0 0 688.74 461.48" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <clipPath id="a-m">
                            <path d="m559.07 227.88c-.01-58.11-23.51-110.56-61.58-148.66-38.1-38.07-90.55-61.57-148.66-61.58h-163.66-51.17v214.83c.01 58.11 23.51 110.56 61.58 148.66 38.1 38.07 90.55 61.57 148.66 61.58h163.67 51.17v-214.83z" />
                        </clipPath>
                        <g clip-path="url(#a-m)">
                            <image class="whoWeAreBgImage" height="800" overflow="visible" transform="matrix(.5373 0 0 .5373 132.4536 16.0315)" width="800" :xlink:href="data.whoWeAreTop.image.src"><desc>{{ data.whoWeAreTop.image.alt }}</desc></image>
                        </g>

                        <!-- red -->
                        <path class="mt-right-horiz" d="m672.46 442.46h-113.39" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="mt-right-arc" d="m559.07 327.58c114.88 0 113.39 114.88 113.39 114.88" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="mt-left-fill" d="m320.02 442.71h-94.38c-57.6-.01-109.6-23.45-147.36-61.43-37.74-38.01-61.03-90.34-61.04-148.31v-214.33h117.68v214.34c0 67.35 24.36 115.97 71.05 157.1 27.82 24.5 68.78 45.16 114.05 52.63z" fill="#dfd9c8" />
                        <g style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                            <path class="mt-left-outer" d="m17 18.99v216.61c0 114.19 92.57 206.76 206.76 206.76h113.5" />
                            <path class="mt-left-top" d="m135.31 18.99h-118.31" />
                            <path class="mt-left-inner" d="m559.07 442.71h-51.05-163.28c-57.97-.01-110.3-23.45-148.31-61.44-37.99-38.01-61.43-90.34-61.44-148.32v-214.31" />
                            <path class="mt-left-right" d="m134.99 18.64h51.05 163.29c57.97.01 110.3 23.45 148.31 61.44 37.99 38.01 61.43 90.34 61.44 148.32v214.32" />
                        </g>

                        <!-- tan -->
                        <path class="mt-right-horiz" d="m672.46 442.46h-113.39" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="mt-right-arc" d="m559.07 327.58c114.88 0 113.39 114.88 113.39 114.88" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="mt-left-fill" d="m320.02 442.71h-94.38c-57.6-.01-109.6-23.45-147.36-61.43-37.74-38.01-61.03-90.34-61.04-148.31v-214.33h117.68v214.34c0 67.35 24.36 115.97 71.05 157.1 27.82 24.5 68.78 45.16 114.05 52.63z" fill="#dfd9c8" />
                        <g style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                            <path class="mt-left-outer" d="m17 18.99v216.61c0 114.19 92.57 206.76 206.76 206.76h113.5" />
                            <path class="mt-left-top" d="m135.31 18.99h-118.31" />
                            <path class="mt-left-inner" d="m559.07 442.71h-51.05-163.28c-57.97-.01-110.3-23.45-148.31-61.44-37.99-38.01-61.43-90.34-61.44-148.32v-214.31" />
                            <path class="mt-left-right" d="m134.99 18.64h51.05 163.29c57.97.01 110.3 23.45 148.31 61.44 37.99 38.01 61.43 90.34 61.44 148.32v214.32" />
                        </g>

                        <!-- black -->
                        <path class="mt-right-horiz" d="m672.46 442.46h-113.39" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="mt-right-arc" d="m559.07 327.58c114.88 0 113.39 114.88 113.39 114.88" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="mt-left-fill" d="m320.02 442.71h-94.38c-57.6-.01-109.6-23.45-147.36-61.43-37.74-38.01-61.03-90.34-61.04-148.31v-214.33h117.68v214.34c0 67.35 24.36 115.97 71.05 157.1 27.82 24.5 68.78 45.16 114.05 52.63z" fill="#dfd9c8" />
                        <g style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                            <path class="mt-left-outer" d="m17 18.99v216.61c0 114.19 92.57 206.76 206.76 206.76h113.5" />
                            <path class="mt-left-top" d="m135.31 18.99h-118.31" />
                            <path class="mt-left-inner" d="m559.07 442.71h-51.05-163.28c-57.97-.01-110.3-23.45-148.31-61.44-37.99-38.01-61.43-90.34-61.44-148.32v-214.31" />
                            <path class="mt-left-right" d="m134.99 18.64h51.05 163.29c57.97.01 110.3 23.45 148.31 61.44 37.99 38.01 61.43 90.34 61.44 148.32v214.32" />
                        </g>

                    </svg>

                </div>
                <p class="get-to-know-copy__description" v-html="data.getToKnow.description"></p>
            </div>

            <div class="get-to-know__right-image">
                <svg role = "img" aria-label = "A decorative frame border." class="whoWeAreTop" enable-background="new 0 0 716 1200" viewBox="0 0 716 1200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <clipPath id="a">
                        <path d="m702.37 701.75h-271.63c-73.48-.01-139.8-29.72-187.97-77.86-48.14-48.17-77.85-114.49-77.86-187.97v-271.63h64.7 206.94c73.47.01 139.79 29.72 187.96 77.86 48.14 48.17 77.85 114.49 77.86 187.97z" />
                    </clipPath>
                    <path class="yellow-fill" d="m164.73 164.17s-154.78-2.01-154.78 152.77h154.78" fill="#dfd9c8" />

                    <g clip-path="url(#a)">
                        <image class="whoWeAreBgImage" height="800" overflow="visible" transform="matrix(.6725 0 0 .6712 165 165)" width="800" :xlink:href="data.whoWeAreTop.image.src"><desc>{{ data.whoWeAreTop.image.alt }}</desc></image>
                    </g>

                    <g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4">

                        <!-- red -->
                        <path class="top-line" d="m165 12v153" stroke="#b22028" />
                        <path class="middle-right" d="m702.37 701.75v-271.63c-.01-73.47-29.72-139.79-77.86-187.97-48.17-48.14-114.49-77.85-187.96-77.86h-206.94-64.7" stroke="#b22028" />
                        <path class="middle-left" d="m702.37 701.75h-64.7-206.94c-73.47-.01-139.79-29.72-187.96-77.86-48.14-48.17-77.85-114.49-77.86-187.97v-271.63" stroke="#b22028" />
                        <path class="left-arc" d="m165 164s-155-3-155 153" stroke="#b22028" />

                        <path class="left-line" d="m165 317h-155" stroke="#b22028" />
                        <path class="top-arc" d="m320.5 163.5s8-152-155-152" stroke="#b22028" />

                        <path class="bottom-line" d="m563 702v393" stroke="#b22028" />
                        <path class="bottom-arc" d="m563 841c141 0 139-139 139-139" stroke="#b22028" />

                        <circle class="circle-1" cx="563.5" cy="1119.5" r="2" fill="#b22028" />
                        <circle class="circle-2" cx="563.5" cy="1149.5" r="2" fill="#b22028" />
                        <circle class="circle-3" cx="563.5" cy="1180.5" r="2" fill="#b22028" />

                        <!-- tan -->
                        <path class="top-line" d="m165 12v153" stroke="#d6d0b4" />
                        <path class="middle-right" d="m702.37 701.75v-271.63c-.01-73.47-29.72-139.79-77.86-187.97-48.17-48.14-114.49-77.85-187.96-77.86h-206.94-64.7" stroke="#d6d0b4" />
                        <path class="middle-left" d="m702.37 701.75h-64.7-206.94c-73.47-.01-139.79-29.72-187.96-77.86-48.14-48.17-77.85-114.49-77.86-187.97v-271.63" stroke="#d6d0b4" />
                        <path class="left-arc" d="m165 164s-155-3-155 153" stroke="#d6d0b4" />

                        <path class="left-line" d="m165 317h-155" stroke="#d6d0b4" />
                        <path class="top-arc" d="m320.5 163.5s8-152-155-152" stroke="#d6d0b4" />

                        <path class="bottom-line" d="m563 702v393" stroke="#d6d0b4" />
                        <path class="bottom-arc" d="m563 841c141 0 139-139 139-139" stroke="#d6d0b4" />

                        <circle class="circle-1" cx="563.5" cy="1119.5" r="2" fill="#d6d0b4" />
                        <circle class="circle-2" cx="563.5" cy="1149.5" r="2" fill="#d6d0b4" />
                        <circle class="circle-3" cx="563.5" cy="1180.5" r="2" fill="#d6d0b4" />


                        <!-- black -->
                        <path class="top-line" d="m165 12v153" stroke="#1c1b19" />
                        <path class="middle-right" d="m702.37 701.75v-271.63c-.01-73.47-29.72-139.79-77.86-187.97-48.17-48.14-114.49-77.85-187.96-77.86h-206.94-64.7" stroke="#232221" />
                        <path class="middle-left" d="m702.37 701.75h-64.7-206.94c-73.47-.01-139.79-29.72-187.96-77.86-48.14-48.17-77.85-114.49-77.86-187.97v-271.63" stroke="#232221" />
                        <path class="left-arc" d="m165 164s-155-3-155 153" stroke="#232221" />

                        <path class="left-line" d="m165 317h-155" stroke="#232221" />
                        <path class="top-arc" d="m320.5 163.5s8-152-155-152" stroke="#1c1b19" />

                        <path class="bottom-line" d="m563 702v393" stroke="#232221" />
                        <path class="bottom-arc" d="m563 841c141 0 139-139 139-139" stroke="#232221" />

                        <circle class="circle-1" cx="563.5" cy="1119.5" r="2" fill="#232221" />
                        <circle class="circle-2" cx="563.5" cy="1149.5" r="2" fill="#232221" />
                        <circle class="circle-3" cx="563.5" cy="1180.5" r="2" fill="#232221" />

                    </g>
                </svg>

            </div>

        </div>

        <waistband-image :waistbandImageSrc='data.waistbandImage.src' :waistbandImageAltTxt='data.waistbandImage.alt' :waistbandCaption='data.waistbandImage.captionMaxChar'
        :waistbandSource='data.waistbandImage.sourceName'
        :waistbandRole='data.waistbandImage.sourceTitle' />

        <div class="great-ideas" v-scroll-reveal="{ distance: '0px', beforeReveal: revealGreatIdeas }">
            <div class="great-ideas__subheading" v-html="data.greatIdeas.subheading"></div>
            <h2 class="great-ideas__header" v-html="data.greatIdeas.header"></h2>
            <div class="great-ideas__description" v-html="data.greatIdeas.description"></div>

            <div class="great-ideas__apply-images">

                <!-- v-scroll-reveal.reset if we want to replay animation -->
                <div class="great-ideas__apply-images__grid" v-scroll-reveal="{ distance: '0px', beforeReveal: revealApplyImages }">

                    <div class="apply-today">
                        <router-link to="/careers">
                            <div class="btn btn--red-outline">
                                <span v-html="data.applyImages.buttonMessage"></span>
                            </div>
                        </router-link>
                    </div>


                    <!-- letters vertical, numerals horizontal -->
                    <div class="a4">
                        <img :src="data.applyImages.grid.a4.src" :alt="data.applyImages.grid.a4.alt">
                    </div>
                    <div class="a5">
                        <img :src="data.applyImages.grid.a5.src" :alt="data.applyImages.grid.a5.alt">
                    </div>

                    <div class="b4">
                        <img :src="data.applyImages.grid.b4.src" :alt="data.applyImages.grid.b4.alt">
                    </div>
                    <div class="b5">
                        <img :src="data.applyImages.grid.b5.src" :alt="data.applyImages.grid.b5.alt">
                    </div>
                    <div class="b6">
                        <img :src="data.applyImages.grid.b6.src" :alt="data.applyImages.grid.b6.alt">
                    </div>
                    <div class="b7">
                        <img :src="data.applyImages.grid.b7.src" :alt="data.applyImages.grid.b7.alt">
                    </div>
                    <div class="b8">
                        <img :src="data.applyImages.grid.b8.src" :alt="data.applyImages.grid.b8.alt">
                    </div>

                    <div class="shape-leaf"></div>
                    <div class="c5">
                        <img :src="data.applyImages.grid.c5.src" :alt="data.applyImages.grid.c5.alt">
                    </div>
                    <div class="shape-bottom-left"></div>
                    <div class="c7">
                        <img :src="data.applyImages.grid.c7.src" :alt="data.applyImages.grid.c7.alt">
                    </div>
                    <div class="shape-top-right"></div>
                    <div class="d4">
                        <img :src="data.applyImages.grid.d4.src" :alt="data.applyImages.grid.d4.alt">
                    </div>
                    <div class="shape-leaf-2"></div>
                    <div class="d7">
                        <img :src="data.applyImages.grid.d7.src" :alt="data.applyImages.grid.d7.alt">
                    </div>

                    <div class="a1 mobile-hidden">
                        <img :src="data.applyImages.grid.a1.src" :alt="data.applyImages.grid.a1.alt">
                    </div>
                    <div class="a2 mobile-hidden">
                        <img :src="data.applyImages.grid.a2.src" :alt="data.applyImages.grid.a2.alt">
                    </div>
                    <div class="shape-top-left mobile-hidden"></div>

                    <div class="shape-leaf-3 mobile-hidden"></div>
                    <div class="b2 mobile-hidden">
                        <img :src="data.applyImages.grid.b2.src" :alt="data.applyImages.grid.b2.alt">
                    </div>
                    <div class="b3 mobile-hidden">
                        <img :src="data.applyImages.grid.b3.src" :alt="data.applyImages.grid.b3.alt">
                    </div>

                    <div class="shape-leaf-left mobile-hidden"></div>
                    <div class="c2 mobile-hidden">
                        <img :src="data.applyImages.grid.c2.src" :alt="data.applyImages.grid.c2.alt">
                    </div>

                    <div class="shape-top-left-2 mobile-hidden"></div>
                    <div class="d3 mobile-hidden">
                        <img :src="data.applyImages.grid.d3.src" :alt="data.applyImages.grid.d3.alt">
                    </div>

                </div>

            </div>
            <div class="studio-description" v-scroll-reveal="{ distance: '0px', beforeReveal: revealStudioDescription }">

                <svg role = "img" aria-label = "A decorative frame border." version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 712.35 564" style="enable-background:new 0 0 712.35 564;" xml:space="preserve">

                    <g>
                        <defs>
                            <path id="SVGID_1_" d="M17.62,547.07c0-63.13,11.07-123.67,31.37-179.79C122.55,163.94,317.34,18.62,546.07,18.62v528.45H17.62z"/>
                        </defs>
                        <clipPath id="SVGID_2_">
                            <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                        </clipPath>
                        <g style="clip-path:url(#SVGID_2_);">
                            <image style="overflow:visible;" width="529" height="529" class="pdNP6V" id="pdNP6V" :xlink:href="data.studioDescription.img.src"  transform="matrix(1 0 0 1 15.3415 18.345)"><desc>{{ data.studioDescription.img.alt }}</desc></image>
                        </g>
                    </g>

                    <!-- red -->
                    <path class="OVERLAP-Fill" d="M543.7,546.88H391.35c0-152.35,152.35-150.37,152.35-150.37V546.88" fill="#FFFFFF"/>
                    <path class="OVERLAP-Stroke_1_" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M391.35,546.88c0-152.35,152.35-150.37,152.35-150.37"/>
                    <path class="Little-Pie-Horiz" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.44,246.14h152.34"/>
                    <path class="Little-Pie-Curve" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M696.78,246.14c0,152.35-152.35,150.37-152.35,150.37"/>
                    <path class="Big-Pie-Curve" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M15.62,547.07c0-63.13,11.07-123.67,31.37-179.79C120.55,163.94,315.34,18.62,544.07,18.62"/>
                    <path class="BIG-PIE-Horiz" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.07,547.07H15.62"/>
                    <line class="BIG-PIE-Bottom-Vertical" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="544.07" y1="398.22" x2="544.07" y2="547.07"/>
                    <path class="BIG-PIE-Top-Vertical" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.07,18.62v379.6"/>

                    <!-- tan -->
                    <path class="OVERLAP-Fill" d="M543.7,546.88H391.35c0-152.35,152.35-150.37,152.35-150.37V546.88" fill="#FFFFFF"/>
                    <path class="OVERLAP-Stroke_1_" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M391.35,546.88c0-152.35,152.35-150.37,152.35-150.37"/>
                    <path class="Little-Pie-Horiz" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.44,246.14h152.34"/>
                    <path class="Little-Pie-Curve" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M696.78,246.14c0,152.35-152.35,150.37-152.35,150.37"/>
                    <path class="Big-Pie-Curve" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M15.62,547.07c0-63.13,11.07-123.67,31.37-179.79C120.55,163.94,315.34,18.62,544.07,18.62"/>
                    <path class="BIG-PIE-Horiz" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.07,547.07H15.62"/>
                    <line class="BIG-PIE-Bottom-Vertical" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="544.07" y1="398.22" x2="544.07" y2="547.07"/>
                    <path class="BIG-PIE-Top-Vertical" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.07,18.62v379.6"/>

                    <!-- black -->
                    <path class="OVERLAP-Fill" d="M543.7,546.88H391.35c0-152.35,152.35-150.37,152.35-150.37V546.88" fill="#FFFFFF"/>
                    <path class="OVERLAP-Stroke_1_" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M391.35,546.88c0-152.35,152.35-150.37,152.35-150.37"/>
                    <path class="Little-Pie-Horiz" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.44,246.14h152.34"/>
                    <path class="Little-Pie-Curve" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M696.78,246.14c0,152.35-152.35,150.37-152.35,150.37"/>
                    <path class="Big-Pie-Curve" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M15.62,547.07c0-63.13,11.07-123.67,31.37-179.79C120.55,163.94,315.34,18.62,544.07,18.62"/>
                    <path class="BIG-PIE-Horiz" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.07,547.07H15.62"/>
                    <line class="BIG-PIE-Bottom-Vertical" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="544.07" y1="398.22" x2="544.07" y2="547.07"/>
                    <path class="BIG-PIE-Top-Vertical" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M544.07,18.62v379.6"/>


                </svg>

                <div class="studio-description__description" v-html="data.studioDescription.description"></div>

            </div>
        </div>

        <div class="award-winning">
            <div class="award-winning__subheading" v-scroll-reveal="{ distance: '50%', origin: 'left', delay: 0}">{{ data.awardWinning.subheading}}</div>
            <h2 class="award-winning__header" v-scroll-reveal="{ distance: '50%', origin: 'left', delay: 500}">{{ data.awardWinning.header }}</h2>
            <div class="award-winning__game-images">

                <div v-for="(item, i) in Math.ceil((gamesGridTrucated ? GAMES_GRID_COUNT : data.awardWinning.gameImages.length) / GAMES_GRID_COUNT)" :key="i">
                    <div class="grid1">
                        <award-winning-grid-item v-for="(game, j) in data.awardWinning.gameImages.slice((i*GAMES_GRID_COUNT)+0, (i*GAMES_GRID_COUNT)+6)" :num="j+1" :game="game" :key="j" />
                    </div>
                    <div class="grid2">
                        <award-winning-grid-item v-for="(game, j) in data.awardWinning.gameImages.slice((i*GAMES_GRID_COUNT)+6, (i*GAMES_GRID_COUNT)+11)" :num="j+1" :game="game" :key="j" />
                    </div>
                    <div class="grid3">
                        <award-winning-grid-item v-for="(game, j) in data.awardWinning.gameImages.slice((i*GAMES_GRID_COUNT)+11, (i*GAMES_GRID_COUNT)+17)" :num="j+1" :game="game" :key="j" />
                    </div>
                </div>
            </div>

            <div class="award-winning__full-catalogue" @click="gamesGridTrucated=false" v-if="gamesGridTrucated">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="bar bar--left"></div>
                <p>view full catalogue</p>
                <div class="bar bar--right"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>

        <site-footer></site-footer>

    </div>

</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import WhoWeAreMixin from '../mixins/WhoWeAreTimeline.js'
    import Meta from '../mixins/Meta.js'
    import 'jquery-ui/ui/widgets/slider.js';
    import WaistbandImage from '@/components/WaistbandImage.vue'
    import Footer from '@/components/Footer'
    import awardWinningGridItem from '../components/whoWeAre/AwardWinningGridItem.vue'
    import ArrayHelper from '../helpers/ArrayHelper.js'

    import gsap, {Power2, Power3} from 'gsap'
    import SplitText from "gsap/SplitText"
    gsap.registerPlugin(SplitText)

    export default {
        mixins: [WhoWeAreMixin, Meta],
        components: {
            WaistbandImage,
            'site-footer': Footer,
            awardWinningGridItem
        },
        data() {
            return {
                data: null,
                gamesGridTrucated: true,
                GAMES_GRID_COUNT: 17, // number of items until game grid repeats
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                axios.get(this.$json('/data/whoWeAre.json'))
                    .then(response => {
                        this.data = response.data;
                        this.setMetaData(this.data.meta);

                        // randomize Award Winning games grid
                        ArrayHelper.shuffle(this.data.awardWinning.gameImages);

                        this.$sectionLoaded();

                        Vue.nextTick(() => {
                            this.initTimeline();
                            this.initSlider();
                            this.revealTop();

                            setTimeout(() => {
                                // in mixin
                                this.tl.play();
                                // this.topHeadline.play();
                            }, 600);
                        });
                        this.$checkAgeGate(this.data.ageGate);

                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            revealApplyImages() {
                var whoWeAreGreatIdeasTl = gsap.timeline({})
                let gridItems = ArrayHelper.shuffle($('.great-ideas__apply-images__grid > div'));
                whoWeAreGreatIdeasTl.from(gridItems, 0.6, {
                    opacity: 0,
                    scale: 0.5,
                    stagger:0.1
                });
            },
            revealTop() {
                var topHeadline = gsap.timeline({delay:1})

                topHeadline.from($('.get-to-know-copy__logo--mark'), .25, {
                    x: -50,
                    opacity: 0,
                    ease: Power2.easeInOut
                }, 0);

                topHeadline.from($('.get-to-know-copy__subheading'), .5, {
                    x: -50,
                    letterSpacing: "1em",
                    opacity: 0,
                    ease: Power2.easeInOut
                }, 0);

                topHeadline.from($('.get-to-know-copy__description'), 1, {
                    opacity: 0,
                    x: -50,
                    ease: Power2.easeInOut
                }, 0);

                var mySplitText = new SplitText(".get-to-know-copy__header", {
                    type: "lines",
                    linesClass: 'line-split'
                });

                $('.line-split').each(function(i, el) {
                    var curLine = $(el).wrap('<div class="line-split-wrap"></div>');
                })

                var lines = mySplitText.lines; //an array of all the divs that wrap each character
                topHeadline.from(lines, .5, {
                    yPercent: 100,
                    ease: Power3.easeInOut,
                    stagger:0.03
                }, "-=.75");

            },
            revealStudioDescription() {
                var studioDescriptionTl = gsap.timeline({delay:1})
                studioDescriptionTl.timeScale(2.7);
                let stagger = 0.3;

                studioDescriptionTl.fromTo($('.BIG-PIE-Bottom-Vertical'), 1, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                studioDescriptionTl.fromTo($('.BIG-PIE-Top-Vertical'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, "-=.5");
                studioDescriptionTl.fromTo($('.BIG-PIE-Horiz'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, "-=3");

                studioDescriptionTl.fromTo($('.OVERLAP-Stroke_1_'), 1, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);

                studioDescriptionTl.fromTo($('.Little-Pie-Curve'), 1.5, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1.4);
                studioDescriptionTl.fromTo($('.Little-Pie-Horiz'), 1.5, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2.2);

                studioDescriptionTl.fromTo($('.Big-Pie-Curve'), 1.5, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2);

                studioDescriptionTl.fromTo($('.OVERLAP-Fill'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=3");
                studioDescriptionTl.fromTo($('.pdNP6V'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=2");
                studioDescriptionTl.fromTo($('.studio-description__description > p'), 1.5, { opacity: 0 , x:50}, { opacity: 1, x:0, ease: Power2.easeInOut }, "-=1");
            },

            revealGreatIdeas() {
                var greatIdeas = gsap.timeline({})

                greatIdeas.from($('.great-ideas__subheading'), .5, {
                    x: -50,
                    letterSpacing: "1em",
                    opacity: 1,
                    ease: Power2.easeInOut
                }, 0);

                greatIdeas.from($('.great-ideas__header'), .5, {
                    x: -50,
                    opacity: 1,
                    ease: Power2.easeInOut
                }, .25);

                greatIdeas.from($('.great-ideas__description'), .5, {
                    x: -50,
                    opacity: 1,
                    ease: Power2.easeInOut
                }, .5);
            },
            revealMobileTop() {
                var mobileTopTl = gsap.timeline({delay:1})
                mobileTopTl.timeScale(2.7);
                let stagger = 0.3;

                mobileTopTl.fromTo($('.mt-left-outer'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                mobileTopTl.fromTo($('.mt-left-top'), 1, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                mobileTopTl.fromTo($('.mt-left-right'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, .75);
                mobileTopTl.fromTo($('.mt-left-inner'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, .75);

                mobileTopTl.fromTo($('.mt-right-arc'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2.5);
                mobileTopTl.fromTo($('.mt-right-horiz'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 3);

                mobileTopTl.fromTo($('.left-middle-horizontal'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 3.5);
                mobileTopTl.fromTo($('.left-right'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 3.5);

                mobileTopTl.fromTo($('.left-left-top'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 4.5);
                mobileTopTl.fromTo($('.left-middle'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 4.5);

                mobileTopTl.fromTo($('.left-left'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 5.5);
                mobileTopTl.fromTo($('.left-bottom-horizontal'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 5);

                mobileTopTl.fromTo($('.mt-left-fill'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 4);
            }
        }
    }
</script>
