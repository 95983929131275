<template>
    <div class="waistband-image" v-scroll-reveal>
        <img :src="waistbandImageSrc" :alt="waistbandImageAltTxt" @load="$sr.sync()" v-scroll-reveal="{ distance: '0'}">
        <!-- lets put a 70 character max on the following in the cms -->
        <div class="waistband-image__caption" v-scroll-reveal="{ distance: '100%', origin: 'right', delay: 500, opacity: null}">
            <p>
                {{ waistbandCaption }}
            </p>

            <div class="attribution">{{ waistbandSource }}</div>
            <div class="role">{{ waistbandRole }}</div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "../../node_modules/breakpoint-sass/stylesheets/_breakpoint.scss";
@import "../sass/_variables.scss";
@import "../sass/fonts.scss";

    .waistband-image {
        width: 100%;
        max-width: 1500px;
        margin: 50px auto 140px;
        display: block;
        position: relative;
        @include breakpoint($mobile) {
            margin: 100px auto;
        }

        img {
            width: 100%;
        }

        &__caption {
            width: 70%;
            height: 45%;
            position: absolute;
            bottom: -25%;
            right: 0;
            background-color: $color-white;
            // border-radius: 125px 0 0 0 / 150px 0 0 0;
            border-radius: 20% 0 0 0 / 50% 0 0 0;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-content: center;
            background-image: url('../assets/global/sms-shape--beige.svg');
            background-size: 33%;
            background-repeat: no-repeat;
            background-position: 90% 15px;
            @include breakpoint($mobile) {
                width: 45%;
                height: 40%;
                bottom: 0;
                background-position: 75% 75%;
                border-radius: 125px 0 0 0;
            }

            p {
                margin: 70px 4% 0 30%;
                @include font-metronic-slab-regular();
                color: $color-yellow;
                font-size: 1em;
                letter-spacing: 2px;
                line-height: 1;

                @include breakpoint(375px){
                    margin: 50px 5% 0 29%;
                }
                @include breakpoint($mobile) {
                    // margin: 22% 17% 0 29%;
                    margin: 15% 17% 0 29%;
                    font-size: 2.5vw;
                }
                @include breakpoint($lockout){
                    font-size: 2.5em;
                }

                &::before {
                    content: "\201C";
                    font-size: 5em;
                    position: absolute;
                    // left: 16%;
                    // top: 17%;
                    left: 12%;
                    top: 6px;
                    color: $color-beige;

                    @include breakpoint(375px){
                        left: 12%;
                        top: 11px;
                    }

                    @include breakpoint(400px){
                        top: 4vw;
                    }

                    @include breakpoint(500px){
                        top: 5vw;
                        left: 15%;
                    }

                    @include breakpoint($mobile) {
                        font-size: 4em;
                        left: 17%;
                        // top: 24%;
                        top: 19%;
                    }

                    @include breakpoint(800px){
                        top: 20%;
                    }

                    @include breakpoint(950px){
                        top: 22%;
                    }

                    @include breakpoint(1025px){
                        top: 22%;
                    }

                    @include breakpoint(1200px){
                        top: 21%;
                    }

                    @include breakpoint(1500px){
                        top: 21%;
                    }
                }
            }

            .attribution, .role {
                font-size: 7px;
                text-transform: uppercase;
                font-family: urw-din,serif;
                font-weight: 700;
                margin: 10px 0 0 0;
                
                width: 100%;
                position: relative;
                left: 30%;

                @include breakpoint(375px){
                    left: 29%;
                    // top: 14%;
                    font-size: 8px;
                }

                @include breakpoint($mobile) {
                    // left: 1%;
                    // display: inline-block;
                    font-size: .75vw;
                    margin-top: 20px;
                    letter-spacing: 2px;
                    // width: auto;
                }

                @include breakpoint(1500px){
                    font-size: .75em;
                }
            }

            .attribution {
                margin-right: 10px;

                // &::after {
                //     content: "\2022";
                //     margin-left: 10px;
                // }
            }

            .role {
                font-weight: 400;
                font-style: italic;
                margin-top: 1px;
                // @include breakpoint($mobile) {
                //     margin-top: 20px;
                // }
            }
        }
    }
</style>

<script>
    export default {
        props: ['waistbandImageSrc', 'waistbandImageAltTxt', 'waistbandCaption', 'waistbandSource', 'waistbandRole'],
    }
</script>